import { footerContent } from "../../constant/Footer";
const Location = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
      {footerContent.map((cnt, index) => (
        <div key={index} className="text-font w-[95%] md:mx-auto">
          <h4 className="text-[18px] font-semibold">{cnt.office}</h4>
          <h6 className="font-semibold">{cnt.location}</h6>
          <div className="mt-2">
            {cnt.tel && (
              <span className="custom-grid">
                <p className="font-semibold">Tel:</p>
                <p>{cnt.tel}</p>
              </span>
            )}

            {cnt.fax && (
              <span className="custom-grid">
                <p className="font-semibold">Fax:</p>
                <p>{cnt.fax}</p>
              </span>
            )}

            {cnt.mob && (
              <span className="custom-grid">
                <p className="font-semibold">Mob:</p>
                <p>{cnt.mob}</p>
              </span>
            )}

            {cnt.email && (
              <span className="custom-grid">
                <p className="font-semibold">Email:</p>
                <p>{cnt.email}</p>
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Location;
