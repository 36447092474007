import "./MobileNavBtn.css";

const MobileNavBtn = ({ onClick, active }) => {
  return (
    <button onClick={onClick} className={`nav-btn ${active ? "active" : ""}`}>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
};

export default MobileNavBtn;
