import { Link } from "react-router-dom";

const Navigation = ({ className, onClick }) => {
  return (
    <nav>
      <ul className={className}>
        <li>
          <Link onClick={onClick} to={"/"}>
            Home
          </Link>
        </li>
        <li>
          <Link onClick={onClick} to={"/about"}>
            About Us
          </Link>
        </li>
        <li>
          <Link onClick={onClick} to={"/brands"}>
            Brands
          </Link>
        </li>
        <li>
          <Link onClick={onClick} to={"/waranty"}>
            Warranty
          </Link>
        </li>
        <li>
          <Link onClick={onClick} to={"/contact"}>
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
