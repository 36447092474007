import Navigation from "./Navigation";
import HeaderStyle from "./Style";

const MobileNavMenu = ({ active, onClick }) => {
  return (
    <Navigation
      onClick={onClick}
      className={`${HeaderStyle.mobileNav} ${
        active ? "translate-x-[0] opacity-100" : "-translate-x-[100%] opacity-0"
      }`}
    />
  );
};

export default MobileNavMenu;
