import React from "react";
import Container from "../../components/Container/Container";
import Location from "./Location";
import logo from "../../assests/images/logo.png";

const Footer = () => {
  const link = "font-semibold";
  return (
    <footer className="mt-primary pb-10 ">
      <Container>
        <div className="border-b border-black pb-4 mb-10 ">
          <img className="w-[12rem] lg:w-[13rem]" src={logo} alt="" />
        </div>
        <Location />
      </Container>
    </footer>
  );
};

export default Footer;
