import { Route, Routes } from "react-router-dom";

// layout
import Header from "./layout/Header/Header";
import Footer from "./layout/Footer/Footer";
// routes
import { Home, AboutUs, Brands, Contact, Waranty } from "./routes/index";

function App() {
  return (
    <div className="App">
      <Header />

      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="brands" element={<Brands />} />
        <Route path="waranty" element={<Waranty />} />
        <Route path="contact" element={<Contact />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
