import Container from "../../components/Container/Container";
import { WarrantyLogos } from "../../constant/OurBrandsImages";
const WarrantyImages = () => {
  return (
    <div>
      <Container>
        <div className="flex  md:flex-row items-stretch justify-between my-6 gap-x-6  md:w-3/4 md:mx-auto md:gap-x-10  md:my-14">
          {WarrantyLogos.map((logo, index) => (
            <div className="flex-1" key={index}>
              <img
                src={logo}
                alt=""
                className="object-contain md:w-3/4 md:mx-auto h-full"
              />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default WarrantyImages;
