import { useState } from "react";
// UI
import Button from "../components/UI/Button";
import Spinner from "../components/UI/Spinner";
// forms components
import useInput from "./Components/Hook/user-input";
import Input from "./Components/Inputs/Input";
import Textarea from "./Components/Inputs/Textarea";
import inputErrorMessage from "./Components/Messages/inputErrorMessage";

const ContactForm = () => {
  const [isFormError, setIsFormError] = useState(false);
  const [isFromSucess, setIsFormIsScucess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const inputStyle =
    "border-b boder-black w-full py-3 focus:outline-none transition-focus duration-100 ease-in focus:border focus:border-black  focus:px-2";

  const clearMessage = () => {
    setIsFormError(false);
    setIsFormIsScucess(false);
  };
  const {
    value: nameInput,
    isValid: nameISValid,
    HasError: nameHasError,
    inputChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlueHandler,
    reset: nameReset,
  } = useInput((value) => /^[a-zA-Z]+(\s+[a-zA-Z]+)+$/.test(value));

  const {
    value: phoneNumberInput,
    isValid: phoneNumberIsValid,
    HasError: phoneNumberHasError,

    inputChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
    reset: phoneNumberReset,
  } = useInput((value) => /^\d{8,}$/.test(value));

  const {
    value: emailInput,
    isValid: emailISValid,
    HasError: emailHasError,

    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlueHandler,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const {
    value: messageInput,
    isValid: messageISValid,
    HasError: messageHasError,

    inputChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlueHandler,
    reset: messageReset,
  } = useInput((value) => value.trim("") !== "");

  let formIsValid = false;
  if (nameISValid && phoneNumberIsValid && emailISValid && messageISValid) {
    formIsValid = true;
  }

  const formSubmitionHandler = async (event) => {
    event.preventDefault();
    if (!formIsValid) return;

    try {
      setIsLoading(true);
      const formData = new FormData(event.target);
      formData.append("access_key", "d3157a29-a708-48be-894a-c0bc85b16fa6");

      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      }).then((res) => res.json());

      if (res.success) {
        setIsLoading(false);
        setIsFormIsScucess(true);
      } else {
        setIsFormError(true);
        setIsLoading(false);
        throw new Error("Form submission failed");
      }
    } catch (error) {
      console.error(error);
    }

    nameReset();
    phoneNumberReset();
    emailReset();
    messageReset();
  };

  return (
    <div className="w-full md:w-[65%] mx-auto">
      <h1 className="text-3xl font-medium text-center text-font mb-6">
        Contact Us
      </h1>
      <form
        onSubmit={formSubmitionHandler}
        className="border border-[#eee] p-6 md:p-14 shadow-lg shadow-grey-300"
      >
        <input type="hidden" name="Contact-form" value=""></input>

        {isFormError && (
          <div className="flex flex-col  text-center items-center justify-center gap-x-2 pb-4 text-lg text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#ef4444"
              width="24px"
              height="24px"
            >
              <path d="M 6.7070312 4.2929688 L 4.2929688 6.7070312 L 11.585938 14 L 4.2929688 21.292969 L 6.7070312 23.707031 L 14 16.414062 L 21.292969 23.707031 L 23.707031 21.292969 L 16.414062 14 L 23.707031 6.7070312 L 21.292969 4.2929688 L 14 11.585938 L 6.7070312 4.2929688 z " />
            </svg>

            <p className="">Form submission failed. Please try again</p>
          </div>
        )}

        {isFromSucess && (
          <div className="flex flex-col  text-center items-center justify-center gap-x-2 pb-4 text-lg text-green-600">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="green"
              width="24px"
              height="24px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
            </svg>
            <p className="  ">
              Thank you for submitting the form! Your information has been
              successfully received
            </p>
          </div>
        )}

        <Input
          boxStyle={"w-full"}
          inputStyle={inputStyle}
          type={"text"}
          name={"firstName"}
          id={"firstName"}
          value={nameInput}
          placeholder={"Full name"}
          onChange={(event) => {
            nameChangeHandler(event);
            clearMessage();
          }}
          onBlur={nameBlueHandler}
          hasError={nameHasError}
          errorMessage={inputErrorMessage.fullName}
        />

        <Input
          boxStyle={"w-full"}
          inputStyle={inputStyle}
          type={"number"}
          name={"phoneNumber"}
          id={"phoneNumber"}
          value={phoneNumberInput}
          placeholder={"Phone number"}
          onChange={(event) => {
            phoneNumberChangeHandler(event);
            clearMessage();
          }}
          onBlur={phoneNumberBlurHandler}
          hasError={phoneNumberHasError}
          errorMessage={inputErrorMessage.number}
        />

        <Input
          boxStyle={"w-full"}
          inputStyle={inputStyle}
          type={"email"}
          name={"email"}
          id={"email"}
          value={emailInput}
          placeholder={"Your Email"}
          onChange={(event) => {
            emailChangeHandler(event);
            clearMessage();
          }}
          onBlur={emailBlueHandler}
          hasError={emailHasError}
          errorMessage={inputErrorMessage.email}
        />

        <Textarea
          inputStyle={inputStyle}
          id="message"
          name="message"
          placeholder="Your Message"
          value={messageInput}
          onChange={(event) => {
            messageChangeHandler(event);
            clearMessage();
          }}
          onBlur={messageBlueHandler}
          hasError={messageHasError}
          rows={3}
          errorMessage={inputErrorMessage.text}
        />

        <Button
          className={
            "w-1/4 ml-auto block mt-8 flex items-center justify-center gap-x-2"
          }
        >
          {" "}
          {isLoading && <Spinner />} Submit
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
