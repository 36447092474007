import { Link } from "react-router-dom";
import logo from "../../../assests/images/logo.png";
const Logo = () => {
  return (
    <Link to={"/"}>
      <div className="w-[10rem] lg:w-[13rem]">
        <img src={logo} alt="" />
      </div>
    </Link>
  );
};

export default Logo;
