import {
  hooverLogo,
  dunloopLogo,
  taurusLogo,
  asimcoLogo,
} from "./OurBrandsImages";
export const BrandsContent = [
  {
    image: hooverLogo,
    text: "For over 100 years, Hoover has made quality appliances with the future in mind. We provide clever, affordable products that are easy to use and meet our customers' needs.",
    link: "http://www.healdco.com/hoover/",
  },
  {
    image: dunloopLogo,
    text: "Dunlop is a company with a rich history, and one that is steeped in innovation in the world of tires. Dunlop has grown to become a globally recognized brand and a leader in the field of tires. ",
    link: "https://www.dunlop-mea.com/en-lb/",
  },
  {
    image: taurusLogo,
    text: "Taurus group is dedicated to the design, manufacture and marketing of a multitude of products in the small appliance sector and specialized in meeting the needs of homes around the world, taking care of making life more comfortable for people.",
    link: "https://instagram.com/tauruslebanon?igshid=YmMyMTA2M2Y=",
  },
  {
    image: asimcoLogo,
    text: "Asymco batteries is part of A-map group, an automotive aftermarket global player, specialized in the marketing and distribution of aftermarket automotive spare parts, automotive batteries, tires, and lubricants.",
    link: "https://instagram.com/stopandgoleb?igshid=YmMyMTA2M2Y",
  },
];
