import Container from "../../components/Container/Container";
import ContactForm from "../../form/ContactForm";

const Contact = () => {
  return (
    <main>
      <section className="mt-secondary lg:mt-primary">
        <Container>
          <ContactForm />
        </Container>
      </section>
    </main>
  );
};

export default Contact;
