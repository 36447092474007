import Container from "../../components/Container/Container";
import BrandBox from "./BrandBox";
import { BrandsContent } from "../../constant/BrandsContent";
const Brands = () => {
  return (
    <main>
      <section className="mt-secondary md:mt-primary">
        <Container>
          <div className="grid grid-cols-1 lg:grid-cols-2  gap-16 lg:gap-32 ">
            {BrandsContent.map((cnt, index) => (
              <BrandBox
                key={index}
                image={cnt.image}
                to={cnt.link}
                text={cnt.text}
              />
            ))}
          </div>
        </Container>
      </section>
    </main>
  );
};

export default Brands;
