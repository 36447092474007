import { useState } from "react";
// UI
import Container from "../../components/Container/Container";
import Button from "../../components/UI/Button";
import Spinner from "../../components/UI/Spinner";
// form components
import useInput from "../../form/Components/Hook/user-input";
import Input from "../../form/Components/Inputs/Input";
import inputErrorMessage from "../../form/Components/Messages/inputErrorMessage";

const WarrantyForm = () => {
  const inputStyle =
    "w-full border border-gray-500 rounded-[4px] p-3 placeholder:text-sm text-font bg-white";

  const [submitClicked, setSubmitClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const clearErrorHandler = () => {
    setSubmitClicked(false);
    setIsFormSuccess(false);
    setIsFormError(false);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  const {
    value: firstNameInput,
    isValid: firstNameIsValid,
    isTouched: firstNameIsTouched,
    HasError: firstNameHasError,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHanlder,
    reset: firstNameReset,
  } = useInput((value) => /^[a-zA-Z]+$/.test(value));

  const {
    value: lastNameInput,
    isValid: lastNameIsValid,
    isTouched: lastNameIsTouched,
    HasError: lastNameHasError,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHanlder,
    reset: lastNameReset,
  } = useInput((value) => /^[a-zA-Z]+$/.test(value));

  const {
    value: phoneNumberInput,
    isValid: phoneNumberIsValid,
    isTouched: phoneNumberIsTouched,
    HasError: phoneNumberHasError,
    inputChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHanlder,
    reset: phoneNumberReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: emailInput,
    isValid: emailIsValid,
    isTouched: emailIsTouched,
    HasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHanlder,
    reset: emailReset,
  } = useInput((value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  });

  const {
    value: serialNumberInput,
    isValid: serialNumberIsValid,
    isTouched: serialNumberIsTouched,
    HasError: serialNumberHasError,
    inputChangeHandler: serialNumberChangeHandler,
    inputBlurHandler: serialNumberBlurHanlder,
    reset: serialNumberReset,
  } = useInput((value) => /^\d{8,}$/.test(value));

  const {
    value: dealerNameInput,
    isValid: dealerNameIsValid,
    isTouched: dealerNameIsTouched,
    HasError: dealerNameHasError,
    inputChangeHandler: dealerNameChangeHandler,
    inputBlurHandler: dealerNameBlurHanlder,
    reset: dealerNameReset,
  } = useInput((value) => value.trim("") !== "");

  const {
    value: warrantryNumberInput,
    isValid: warrantryNumberIsValid,
    isTouched: warrantryNumberIstouched,
    HasError: warrantryNumberHasError,
    inputChangeHandler: warrantryNumberChangeHandler,
    inputBlurHandler: warrantryNumberBlurHanlder,
    reset: warrantryNumberReset,
  } = useInput((value) => /^\d{4,}$/.test(value));

  const {
    value: modalCodeInput,
    isValid: modalCodeIsValid,
    isTouched: modalCodeIsTouched,
    HasError: modalCodeHasError,
    inputChangeHandler: modalCodeChangeHandler,
    inputBlurHandler: modalCodeBlurHanlder,
    reset: modalCodeReset,
  } = useInput((value) => /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/.test(value));

  // select section
  const [brandValue, setBrandValue] = useState("");
  const [applianceValue, setApplianceValue] = useState("");

  const handleBrandChange = (event) => {
    setBrandValue(event.target.value);
  };

  const handleApplianceChange = (event) => {
    setApplianceValue(event.target.value);
  };

  let formIsValid =
    firstNameIsValid &&
    lastNameIsValid &&
    phoneNumberIsValid &&
    emailIsValid &&
    modalCodeIsValid &&
    serialNumberIsValid &&
    dealerNameIsValid &&
    warrantryNumberIsValid &&
    brandValue &&
    applianceValue;

  const formSubmitHandler = async (event) => {
    event.preventDefault();

    if (!formIsValid) {
      setSubmitClicked(true);
      return;
    }

    const warrantyData = {
      firstname: firstNameInput,
      lastname: lastNameInput,
      phonenumber: phoneNumberInput,
      email: emailInput,
      brand: brandValue,
      typeofAppliance: applianceValue,
      modaleCode: modalCodeInput,
      serialNumber: serialNumberInput,
      dealerName: dealerNameInput,
      warrantyName: warrantryNumberInput,
    };

    try {
      setIsLoading(true);
      const response = await fetch(
        "https://api.dunloptireslebanon.com/public/api/healdco",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(warrantyData),
        }
      );

      if (!response.ok) {
        setIsFormError(true);
        setIsLoading(false);
        handleScrollToTop();
        console.log(response);
      } else {
        setIsFormSuccess(true);
        setIsLoading(false);
        handleScrollToTop();
        const data = await response.json();
        console.log(data);
      }
    } catch (error) {
      setIsFormError(true);
      setIsLoading(false);
      console.error(error.message);
    }

    firstNameReset();
    lastNameReset();
    phoneNumberReset();
    emailReset();
    setApplianceValue("");
    setBrandValue("");
    modalCodeReset();
    serialNumberReset();
    dealerNameReset();
    warrantryNumberReset();
  };

  return (
    <section className="bg-gray-100">
      <Container>
        <form
          onSubmit={formSubmitHandler}
          className="py-10 lg:px-10 lg:py-20 flex flex-col lg:gap-2"
        >
          {isFormError && (
            <div className="flex flex-col md:flex-row text-center items-center justify-center gap-x-2 pb-4 text-lg text-red-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#ef4444"
                width="24px"
                height="24px"
              >
                <path d="M 6.7070312 4.2929688 L 4.2929688 6.7070312 L 11.585938 14 L 4.2929688 21.292969 L 6.7070312 23.707031 L 14 16.414062 L 21.292969 23.707031 L 23.707031 21.292969 L 16.414062 14 L 23.707031 6.7070312 L 21.292969 4.2929688 L 14 11.585938 L 6.7070312 4.2929688 z " />
              </svg>

              <p className="">Form submission failed. Please try again</p>
            </div>
          )}

          {isFormSuccess && (
            <div className="flex flex-col md:flex-row text-center items-center justify-center gap-x-2 pb-4 text-lg text-green-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="green"
                width="24px"
                height="24px"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
              </svg>
              <p className="  ">
                Thank you for submitting the form! Your information has been
                successfully received
              </p>
            </div>
          )}
          <div className="flex flex-col md:flex-row items-center gap-x-4">
            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"text"}
              id={"firstName"}
              value={firstNameInput}
              placeholder="&#x202A;First Name (الاسم الأول)&#x202C;"
              onChange={(event) => {
                firstNameChangeHandler(event);
                clearErrorHandler();
              }}
              onBlur={firstNameBlurHanlder}
              hasError={
                firstNameHasError || (!firstNameIsTouched && submitClicked)
              }
              errorMessage={inputErrorMessage.text}
            />

            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"text"}
              id={"lastName"}
              value={lastNameInput}
              placeholder="&#x202A;Last Name (اسم العائلة)&#x202C;"
              onChange={(event) => {
                lastNameChangeHandler(event);
                clearErrorHandler();
              }}
              onBlur={lastNameBlurHanlder}
              hasError={
                lastNameHasError || (!lastNameIsTouched && submitClicked)
              }
              errorMessage={inputErrorMessage.text}
            />
          </div>
          <Input
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            type={"number"}
            id={"phoneNumber"}
            value={phoneNumberInput}
            placeholder="Phone Number (e.g. 03000000) / (رقم الهاتف - مثال: ٠٣٠٠٠٠٠٠)"
            onChange={(event) => {
              phoneNumberChangeHandler(event);
              clearErrorHandler();
            }}
            onBlur={phoneNumberBlurHanlder}
            hasError={
              phoneNumberHasError || (!phoneNumberIsTouched && submitClicked)
            }
            errorMessage={inputErrorMessage.text}
          />
          <Input
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            type={"email"}
            id={"email"}
            value={emailInput}
            placeholder="Email Address (e.g. john@example.com) (البريد الإلكتروني)"
            onChange={(event) => {
              emailChangeHandler(event);
              clearErrorHandler();
            }}
            onBlur={emailBlurHanlder}
            hasError={emailHasError || (!emailIsTouched && submitClicked)}
            errorMessage={inputErrorMessage.email}
          />
          {/* select */}
          <div className="flex flex-col md:flex-row items-center gap-x-4 mb-1">
            {/* brand */}
            <span className="w-full">
              <select
                className={inputStyle}
                id="my-select"
                value={brandValue}
                onChange={handleBrandChange}
              >
                <option value="">
                  &#x202A;-- Select a brand (اختر العلامة التجارية)&#x202C; --{" "}
                </option>
                <option value="hoover">Hoover</option>
                <option value="matic">Matic</option>
                <option value="taurus">Taurus</option>
              </select>

              <p
                className={`text-red-500 text-sm my-1 ${
                  submitClicked && !brandValue ? "opacity-100" : "opacity-0"
                }`}
              >
                Please select a brand
              </p>
            </span>
            {/* appliance */}
            <span className="w-full">
              <select
                className={inputStyle}
                value={applianceValue}
                onChange={handleApplianceChange}
              >
                <option value="">
                  &#x202A;-- Type of Appliance (نوع الجهاز) --&#x202C;
                </option>
                <option value="small-home">Small Home Appliance</option>
                <option value="vacuum-cleaner">Vacuum cleaner</option>
                <option value="wahsing-machine">Washing machine</option>
                <option value="dryer">Dryer</option>
                <option value="dishwasher">Dishwasher</option>
                <option value="refrigerator">Refrigerator</option>
              </select>

              <p
                className={`text-red-500 text-sm my-1 ${
                  submitClicked && !applianceValue ? "opacity-100" : "opacity-0"
                }`}
              >
                Please select an appliance
              </p>
            </span>

            {/* model */}
            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"text"}
              id={"modalcode"}
              value={modalCodeInput}
              placeholder="&#x202A;Model Code (e.g. HL C9LG-80) (رمز الطراز)&#x202C;"
              onChange={(event) => {
                modalCodeChangeHandler(event);
                clearErrorHandler();
              }}
              onBlur={modalCodeBlurHanlder}
              hasError={
                modalCodeHasError || (!modalCodeIsTouched && submitClicked)
              }
              errorMessage={inputErrorMessage.text}
            />
          </div>

          <Input
            boxStyle={"w-full"}
            inputStyle={inputStyle}
            type={"number"}
            id={"serialNumber"}
            value={serialNumberInput}
            placeholder="Serial Number / Minimum 8 digits (الرقم التسلسلي  / الحد الأدنى 8 أرقام)"
            onChange={(event) => {
              serialNumberChangeHandler(event);
              clearErrorHandler();
            }}
            onBlur={serialNumberBlurHanlder}
            hasError={
              serialNumberHasError || (!serialNumberIsTouched && submitClicked)
            }
            errorMessage={inputErrorMessage.text}
          />

          <div className="flex flex-col md:flex-row items-center gap-x-4">
            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"text"}
              id={"dealerName"}
              value={dealerNameInput}
              placeholder="Dealer Name (اسم التاجر)"
              onChange={(event) => {
                dealerNameChangeHandler(event);
                clearErrorHandler();
              }}
              onBlur={dealerNameBlurHanlder}
              hasError={
                dealerNameHasError || (!dealerNameIsTouched && submitClicked)
              }
              errorMessage={inputErrorMessage.text}
            />
            <Input
              boxStyle={"w-full"}
              inputStyle={inputStyle}
              type={"number"}
              id={"warrantyNumber"}
              value={warrantryNumberInput}
              placeholder="Warranty Number / Minimum 4 digits (رقم الضمان / الحد الأدنى 4 أرقام)"
              onChange={(event) => {
                warrantryNumberChangeHandler(event);
                clearErrorHandler();
              }}
              onBlur={warrantryNumberBlurHanlder}
              hasError={
                warrantryNumberHasError ||
                (!warrantryNumberIstouched && submitClicked)
              }
              errorMessage={inputErrorMessage.text}
            />
          </div>

          <div className="flex justify-end">
            <Button
              className={"w-1/4 flex items-center gap-x-2 justify-center"}
            >
              {isLoading && <Spinner />}Submit
            </Button>
          </div>
        </form>
      </Container>
    </section>
  );
};

export default WarrantyForm;
