import React from "react";
import Container from "../../components/Container/Container";
import AutoPlaySlider from "../../components/ImageSlider/ImageSlider";
import { sliderImage } from "./HomeImage";

const Hero = () => {
  return (
    <section className="mt-secondary md:mt-primary">
      <Container>
        <div className="mb-10 lg:w-3/4 lg:mb-20">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl text-font font-medium">
            Heald Trading is an exclusive wholesale distributor and certified
            retailer for international brands.
          </h1>
        </div>
        <AutoPlaySlider images={sliderImage} />
      </Container>

      <div className="hidden md:grid grid-cols-4 gap-x-6 items-center ">
        {sliderImage.map((image, index) => (
          <img
            src={image}
            key={index}
            alt=""
            className="w-full h-full object-cover"
          />
        ))}
      </div>
    </section>
  );
};

export default Hero;
