import Container from "../../components/Container/Container";

const WarantyHeader = () => {
  return (
    <section className="mt-secondary mb-10 md:mb-0">
      <Container>
        <div className="text-center border-b-2 border-black w-max mx-auto pb-6">
          <h1 className="text-2xl lg:text-4xl text-font font-medium">
            Warranty Registration
          </h1>
        </div>
      </Container>
    </section>
  );
};

export default WarantyHeader;
