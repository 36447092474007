// UI
import Container from "../../components/Container/Container";
// data
import { imageOne, imageTwo, imagethree, imageFour } from "./HomeImage.js";
const WhoWeAre = () => {
  return (
    <section className="mt-secondary md:mt-primary">
      <Container>
        <div className="flex flex-col gap-20 md:gap-secondary lg:gap-primary">
          <div className="flex flex-col md:flex-row items-center gap-14">
            <div className="flex-1 order-2 md:order-1">
              <p className="text-font text-[18px]">
                Heald Trading Company is an exclusive wholesale distributor and
                certified retailer for international brands since 1959.We
                believe that every home and every car is our area of expertise,
                fueling our passion to provide unique and innovative solutions
                to meet our customers’ needs.
              </p>
            </div>
            <div className="flex-1 order-1 md:order-2 ">
              <img
                src={imageOne}
                alt=""
                className="shadow-xl  shadow-grey-700"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-stretch gap-14">
            <div className="flex-1">
              <img
                src={imageTwo}
                alt=""
                className="shadow-xl  shadow-grey-700"
              />
            </div>
            <div className="flex-1 ">
              <img
                src={imagethree}
                alt=""
                className="shadow-xl  shadow-grey-700 min-h-full min-w-full object-cover"
              />
            </div>
          </div>

          <div className="flex flex-col gap-secondary">
            <div className="flex-1">
              <p className="text-2xl lg:text-3xl  text-font font-medium text-center">
                Our team is constantly working to optimize brand awareness and
                expansion in the market. Over the years, the company has
                developed quite a reputation expanding its portfolio of brands
                to suit the Lebanese customer.
              </p>
            </div>
            <div className="flex-1">
              <img src={imageFour} alt="" className="w-full" />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WhoWeAre;
