import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// CSS
import "./Slider.css";

const AutoPlaySlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 400,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };

  return (
    <div className="md:hidden">
      <Slider {...settings}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt=""
            style={{ objectFit: "cover" }}
            className={"slider-image"}
          />
        ))}
      </Slider>
    </div>
  );
};

export default AutoPlaySlider;
