// Hero Images
import sliderImageOne from "../../assests/images/full-taurus.JPG";
import sliderImageTwo from "../../assests/images/slider-2.jpg";
import sliderImageThree from "../../assests/images/slider-3.jpg";
import sliderImageFour from "../../assests/images/slider-4.jpg";

// who we are
import imageOne from "../../assests/images/image-3.jpg";
import imageTwo from "../../assests/images/image-1.jpg";
import imagethree from "../../assests/images/slider-1.jpeg";
import imageFour from "../../assests/images/image-4.jpg";

export { imageOne, imageTwo, imagethree, imageFour };

export const sliderImage = [
  sliderImageTwo,
  sliderImageThree,
  sliderImageOne,
  sliderImageFour,
];
