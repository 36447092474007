import MissionAndVision from "./MissionAndVision";
import History from "./History";

const AboutUs = () => {
  return (
    <main>
      <MissionAndVision />
      <History />
    </main>
  );
};

export default AboutUs;
