const BrandBox = ({ image, text, to }) => {
  return (
    <a href={to} target="_blank" rel="noreferrer">
      <div className="sm:p-10 border border-transparent hover:border-gray-200 ">
        <div className="w-[full] h-[6rem] md:h-[8rem] mb-4">
          <img src={image} alt="" className="w-full h-full object-contain " />
        </div>
        <div>
          <p className="text-font text-center">{text}</p>
        </div>
      </div>
    </a>
  );
};

export default BrandBox;
