import WarantyHeader from "./WarantyHeader";
import WarrantyForm from "./WarrantyForm";
import WarrantyImages from "./WarrantyImages";

const Warranty = () => {
  return (
    <main>
      <WarantyHeader />
      <WarrantyImages />
      <WarrantyForm />
    </main>
  );
};

export default Warranty;
