import Hero from "./Hero";
import WhoWeAre from "./WhoWeAre";
import OurBrands from "./OurBrands";
import Company from "./Company";

const Home = () => {
  return (
    <main>
      <Hero />
      <WhoWeAre />
      <OurBrands />
      <Company />
    </main>
  );
};

export default Home;
