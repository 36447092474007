import Container from "../../components/Container/Container";

const MissionAndVision = () => {
  const listStyle = {
    box: "flex flex-col gap-2",
    header: "text-2xl text-font font-medium",
    text: "text-font",
  };

  return (
    <section className="mt-secondary md:mt-primary">
      <Container>
        <div className="flex flex-col lg:flex-row gap-14 lg:gap-32">
          <div className="lg:w-[40%] flex flex-col gap-2 ">
            <h1 className="text-4xl text-font font-semibold">
              Mission and Vision
            </h1>
            <h4 className="text-font text-[18px] md:text-xl md:leading-[1.6]">
              We make Heald Trading our customers’ go to home and car appliances
              choice. By bringing the rest of the world in Lebanon, we aim to be
              the pillar in your everyday life.{" "}
            </h4>
          </div>
          <div className="lg:w-[60%] flex flex-col gap-14">
            <div className={`${listStyle.box}`}>
              <h2 className={`${listStyle.header}`}>1. Customer commitment</h2>
              <p className={listStyle.text}>
                {" "}
                We aspire to provide our expertise in all we do putting
                customers’ needs and experience in the center of our strategies.{" "}
              </p>
            </div>
            <div className={`${listStyle.box}`}>
              <h2 className={`${listStyle.header}`}>2. Integrity</h2>
              <p className={listStyle.text}>
                We are consistently open, honest, ethical and genuine.
              </p>
            </div>
            <div className={`${listStyle.box}`}>
              <h2 className={`${listStyle.header}`}>
                3. Entrepreneurial Spirit
              </h2>
              <p className={listStyle.text}>
                {" "}
                We are entrepreneur and thus take risks, reach beyond borders,
                boundaries and experiment. We are also continuously moving
                forward, innovating, and improving.
              </p>
            </div>
            <div className={`${listStyle.box}`}>
              <h2 className={`${listStyle.header}`}>
                4. Build a Positive Team and Family Spirit{" "}
              </h2>
              <p className={listStyle.text}>
                We strive to establish and maintain a culture of warmth and
                belonging, where everyone is welcome.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default MissionAndVision;
