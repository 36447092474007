import { Link } from "react-router-dom";
import Container from "../../components/Container/Container";
import Button from "../../components/UI/Button";

const Company = () => {
  const scrollToTopHanlder = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className="mt-secondary md:mt-primary">
      <Container>
        <div className="flex flex-col text-center md:w-2/3 mx-auto">
          <h1 className="text-3xl text-font font-bold">Who We Are</h1>
          <p className="text-xl text-font my-10">
            Heald trading has always been the crossover between Lebanon and the
            rest of the world. With the dedication to provide premium
            international products and services to your life. The company was
            founded in 1959 by Norman and John Jolly, two British expats that
            has selected Lebanon to be their new home.{" "}
          </p>

          <Link to={"about"} onClick={() => scrollToTopHanlder()}>
            <Button className={"text-[18px]"}>See full story</Button>
          </Link>
        </div>
      </Container>
    </section>
  );
};

export default Company;
