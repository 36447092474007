export const footerContent = [
  {
    office: "HEAD OFFICE",
    location:
      "Dora Highway, Abou Nader Bldg, Ground Floor | P.O.Box. 40-13, Beirut, Lebanon",
    tel: "+961 1 255 921",
    fax: "+961 5 922 961",
    email: "heald@healdco.com",
  },
  {
    office: "Dunlop Service Center",
    location: "Dora Highway, Facing T. Gargour & Fils, Beirut",
    tel: "+961 1 255 921 /2/3/4",
    fax: "+961 1 255 924",
    mob: "+961 3 366 316",
  },
  {
    office: "Home Appliance Service Center",
    location: "Damascus Highway, SCIALE Bldg, Jamhour",
    tel: "+961 5 922 963",
    email: "service@healdco.com",
    mob: "+961 76 766 996",
  },
];
