import { useState } from "react";
// UI
import Container from "../../components/Container/Container";
// header components
import Logo from "./components/Logo";
import Navigation from "./components/Navigation";
import MobileNavBtn from "./components/MobileNavBtn";
import MobileNavMenu from "./components/MobileNavMenu";
// style
import HeaderStyle from "./components/Style";
const Header = () => {
  const [active, setActive] = useState(false);

  const mobileMenuHandler = () => {
    setActive((cur) => !cur);
  };
  return (
    <header>
      <Container>
        <div className="flex items-center justify-between pt-4">
          <Logo />
          <Navigation className={HeaderStyle.desktopNav} />
          <MobileNavBtn onClick={mobileMenuHandler} active={active} />
        </div>
        <MobileNavMenu active={active} onClick={mobileMenuHandler} />
      </Container>
    </header>
  );
};

export default Header;
