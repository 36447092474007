import Container from "../../components/Container/Container";

import { imageOne, imagetwo, imagethree } from "./AboutUsImage";
const History = () => {
  return (
    <section className="mt-secondary md:mt-primary">
      <Container>
        <div className="f">
          <h1 className="text-4xl text-font font-semibold">Our History</h1>
          <div className="lg:w-[60%] lg:pl-16 lg:ml-auto mt-2">
            <p className="text-font text-[18px] md:text-xl md:leading-[1.6]">
              Heald trading has always been the crossover between Lebanon and
              the rest of the world. With the dedication to provide premium
              international products and services to your life. The company was
              founded in 1959 by Norman and John Jolly, two British expats that
              has selected Lebanon to be their new home.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 my-14 lg:my-24 ">
            <img src={imageOne} alt="" className="w-full" />
            <img src={imagetwo} alt="" className="w-full lg:mt-10" />
            <img src={imagethree} alt="" className="w-full" />
          </div>

          <div className="flex flex-col lg:flex-row item-conter gap-10 lg:gap-20 ">
            <p className="flex-1 text-[18px] md:text-xl text-font md:leading-[1.6]">
              They envisioned Heald Trading to be an independent import/export
              company and in 1959, registered the entity as the official local
              distributor for Dunlop tires and Hoover home appliances. Ever
              since, the company remained in custody of the Jolly’s up until the
              beginning of the civil war in 1975. The company was handed over to
              former Heald employees up until 1988.
            </p>
            <p className="flex-1 text-[18px] md:text-xl text-font md:leading-[1.6]">
              In 1988, the company had been purchased and managed by Cecile
              Kfoury and Moussa Al Farhan. Finally, in 2015, the company had
              been purchased and managed by the Boustany Family who has decades
              of local market expertise in import and export. The company is
              working to expand its portfolio of brands and added Taurus small
              appliances in 2017, Asimco batteries brand in 2019 and is always
              seeking for new opportunities.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default History;
