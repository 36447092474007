import { Link } from "react-router-dom";
import Container from "../../components/Container/Container";
import Button from "../../components/UI/Button";
// logos
import { logos } from "../../constant/OurBrandsImages";

const OurBrands = () => {
  const scrollToTopHanlder = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className="mt-secondary md:mt-primary bg-gray-100 pt-10 pb-16">
      <Container>
        <div className="flex flex-col text-center md:w-2/3 mx-auto">
          <h1 className="text-3xl text-font font-bold">Our Brands</h1>
          <p className="text-xl text-font mt-4">
            Heald Trading is a wholesale distributor and certifed retailer for a
            protfolio of international brands in the home appliance and
            automotive industry.
          </p>
        </div>

        <div className="grid grid-cols-2 md:w-[80%] md:mx-auto lg:grid-cols-4 gap-4 mt-10 ">
          {logos.map((logo, index) => (
            <div key={index} className=" border border-black">
              <img src={logo} alt="" className="w-full h-full" />
            </div>
          ))}
        </div>

        <Link to={"brands"} onClick={() => scrollToTopHanlder()}>
          <Button className={"mt-20 mx-auto block text-[18px]"}>
            Discover More
          </Button>
        </Link>
      </Container>
    </section>
  );
};

export default OurBrands;
